import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getIsFeedbackPageOpen } from '@wix/da-shared-react/pkg/SiteHeader/redux/selectors';
import { appLoaded } from '../../actions/app';
import { Props } from './App';
import { AppState } from '../../types/store';
import { isPWA } from '@wix/da-shared-react/pkg/utils/isPWA';
import { getIsMobile } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  getCurrentItem,
  getIsDuperbrowseActive,
} from '@wix/da-shared-react/pkg/Duperbrowse/redux/selectors';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';

type StateProps = Pick<
  Props,
  | 'environment'
  | 'deviation'
  | 'isFeedbackPageOpen'
  | 'isStash'
  | 'PWAInstallBannerVisible'
>;
type DispatchProps = Pick<Props, 'appLoaded'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  environment: getEnv(state).type,
  deviation: getCurrentItem(state as any),
  isFeedbackPageOpen: getIsFeedbackPageOpen(state),
  isDuperbrowseActive: getIsDuperbrowseActive(state as any),
  PWAInstallBannerVisible: !isPWA() && getIsMobile(state),
  isStash: state.isStash,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  appLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps);
