import { Reducer } from 'redux';
import { reducers as sharedReducers } from '@wix/da-shared-react/pkg/redux/shared';
import { default as headReducers } from '@wix/da-react-app/pkg/client/Head/redux/reducers';
import { AppState } from '../types/store';

const buildFakeReducer =
  defaultValue =>
  (state = defaultValue, action) =>
    state;

// This type makes sure we do not forget to add a reducer for any slice
// of the store nor can we add extras for non-existing slices.
// Needed because monday (thursday) mornings exist.
type AppStateKeys = keyof AppState;
type AppStateReducersMapObject = { [k in AppStateKeys]: Reducer<any> };

export const reducers: AppStateReducersMapObject = {
  ...headReducers,
  ...sharedReducers,
  isStash: buildFakeReducer(false),
};

export default reducers;
