import { Middleware } from 'redux';
import rootReducer from '../reducers/index';
import { AppState } from '../types/store';
import rootSaga from '../sagas';
import logger from '../helpers/logger';
import { rootModuleStoreFactory } from '@wix/da-shared-react/pkg/redux/rootModuleStore/factory';
import { IModuleStore } from 'redux-dynamic-modules-core';
import { AppEnvironmentSlice } from '@wix/da-react-app/pkg/client/Environment/redux/types';
import { yieldToMainAsync } from '@wix/da-hooks/pkg/yieldToMain';

export async function configureStore(
  initialState: AppState & AppEnvironmentSlice
): Promise<IModuleStore<AppState>> {
  return new Promise(async resolve => {
    const middleware: Middleware[] = [];

    await yieldToMainAsync(); // Before we make store

    resolve(
      rootModuleStoreFactory<AppState>(
        middleware,
        logger,
        initialState,
        rootReducer,
        rootSaga,
        false
      ) as IModuleStore<AppState>
    );
  });
}

export function configureStoreSSR(
  initialState: AppState & AppEnvironmentSlice
): IModuleStore<AppState> {
  const middleware: Middleware[] = [];

  return rootModuleStoreFactory<AppState>(
    middleware,
    logger,
    initialState,
    rootReducer,
    rootSaga,
    false
  ) as IModuleStore<AppState>;
}
