/* eslint-disable @wix/da/import-scss-last */
import 'react-hot-loader/patch';
import React from 'react';
import Modal from 'react-modal';
import initI18n from '@wix/da-i18next/pkg/presetI18nClientConfig';
// project-base needs to go before app styles
import '@wix/da-ds/src/styles/project-base.scss';
import App from './components/App';
import { AppContainer } from 'react-hot-loader';
import { configureStore } from './store/configure-store';
import { getClientInstance } from '@wix/da-ddt';
import { configureHistoryListener } from '@wix/da-shared-react/pkg/redux/routing/history';
import { hydrateRoot } from 'react-dom/client';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import AppDocumentWrapper from '@wix/da-react-app/pkg/client/AppDocumentWrapper/AppDocumentWrapper';
import { yieldToMainAsync } from '@wix/da-hooks/pkg/yieldToMain';

const ddt = getClientInstance();
ddt.replayServerSideLogs(window['__DDT__']);

const initialI18n = window['__INITIAL_I18N__'];
const initialState = window['__INITIAL_STATE__'];
delete window['__INITIAL_STATE__'];

Modal.setAppElement('body');

configureStore(initialState).then(async store => {
  yieldToMainAsync(); // After store creation

  configureHistoryListener(store);

  initI18n(
    initialI18n,
    getEnv(store.getState()).isDebug,
    getEnv(store.getState()).flags
  );

  yieldToMainAsync(); // After misc config

  const root = hydrateRoot(
    document,
    <AppDocumentWrapper store={store}>
      <App language={initialI18n.locale} />
    </AppDocumentWrapper>
  );

  if (module.hot) {
    module.hot.accept('./components/App', () => {
      // eslint-disable-next-line global-require
      const AppLatest = require('./components/App').default;
      root.render(
        <AppContainer>
          <AppDocumentWrapper store={store}>
            <AppLatest language={initialI18n.locale} />
          </AppDocumentWrapper>
        </AppContainer>
      );
    });
  }
});
